*, *:after, *:before {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: #fbd7e3;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h5.sub {
  color: #943456;
  text-shadow: -0.0075em 0.0075em 0 #fef2f6, 0.005em 0.005em 0 #f6a5c1, 0.01em 0.01em 0 #f7aac4, 0.015em 0.015em #f7aec7, 0.02em 0.02em 0 #f8b3ca, 0.025em 0.025em 0 #f8b7cd, 0.03em 0.03em 0 #f8bcd0, 0.035em 0.035em 0 #f9c0d3;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  font-weight: 900;
  display: block;
  clear: both;
  margin: 0 auto;
}

h1 {
  display: block;
  margin:0 auto;
  font-size: calc(1em + 20vw);
  font-weight: 900;
  color: #fcdde8;
  text-shadow: -0.0075em 0.0075em 0 #fef2f6, 0.005em 0.005em 0 #f6a5c1, 0.01em 0.01em 0 #f7aac4, 0.015em 0.015em #f7aec7, 0.02em 0.02em 0 #f8b3ca, 0.025em 0.025em 0 #f8b7cd, 0.03em 0.03em 0 #f8bcd0, 0.035em 0.035em 0 #f9c0d3;
}